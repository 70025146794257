@font-face {
  font-family: "poppins";
  src: url("/src/Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "IBM";
  src: url("/src/Fonts/IBM.ttf");
}

@font-face {
  font-family: "italic";
  src: url("/src/Fonts/italic.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins";
  scroll-behavior: smooth;
}

:root {
  --main-color: #00a0a3;
  --secondar-color: #ee283b;
}

body {
  background-color: #000;
  position: relative;
}

div.progress {
  position: fixed;
  bottom: 15px;
  right: 20px;
  color: #fff;
}

div.progress div {
  width: 7px;
  height: 7px;
  position: relative;
  margin-bottom: 5px;
  border-radius: 50%;
}

div.progress div.reached {
  background-color: var(--secondar-color);
}

div.progress div.not-reached {
  background-color: var(--main-color);
}

/* General */

/**
  Start of header
*/

header.main-header {
  min-height: 100vh;
  display: block;
  background-image: url("/src/images/Home Page Top BG.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: space-between;
  padding-bottom: 10px;
}

header.main-header div.navbar {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  align-items: center;
  flex-basis: 100%;
}

header.main-header div.navbar img {
  object-fit: contain;
  text-align: center;
  width: 120px;
  height: 35px;
}

header.main-header div.navbar ul li {
  display: inline-block;
  font-family: "poppins";
}

header.main-header div.navbar ul li:last-child {
  margin-right: 0;
}

header.main-header div.navbar ul li a {
  text-decoration: none;
  color: #fff;
}

p.header-odoo {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 275;
}

p.header-odoo span {
  font-size: 1.9em;
  margin-right: 10px;
}

header.main-header div.navbar ul li.selected a {
  color: var(--secondar-color);
}

header.main-header div.header-paragraph {
  font-weight: bold;
  font-size: 3.2em;
  width: 100%;
}

header.main-header div.header-paragraph p {
  float: right;
  font-weight: 700;
  margin-right: 30px;
  line-height: 60px;
}

header.main-header div.header-paragraph span:first-of-type {
  color: #00a0a3;
}

header.main-header div.header-paragraph span:last-of-type {
  color: #ee283b;
}

header.main-header div.header-paragraph p button {
  display: block;
  background: linear-gradient(
    180.2deg,
    #1d8386 0.18%,
    rgba(29, 131, 134, 0) 225.09%
  );
  padding: 20px 50px;
  border-radius: 100px;
  border: none;
  color: #fff;
  font-size: 0.4em;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

header.main-header div.header-end {
  text-align: center;
  flex-basis: 100%;
  padding: 0 10px;
}

header.main-header div.header-end img {
  margin-left: 10px;
  width: 170px;
}

header.main-header div.header-end p {
  font-size: 0.8em;
  margin-top: 20px;
  font-weight: 300;
}

header.main-header div.header-end p img {
  margin-left: -3px;
  width: 90px;
}

header.main-header div.header-end div.flexing {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
}

header.main-header div.header-end div.flexing > span {
  font-size: 2.6rem;
  font-family: "IBM";
  font-weight: 300;
}

header.main-header div.header-end div.flexing span {
  font-family: "IBM";
}

span.be {
  color: #00a0a3;
}

span.sharp {
  color: #ee283b;
}

span.odoo {
  width: 123px;
  height: 39px;
  font-weight: bold;
  font-size: 1.4rem;
}

@media (max-width: 800px) {
  header.main-header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("/src/images/Home Page Top BG.jpg");
    background-position-x: 15%;
  }

  header.main-header div.header-paragraph p {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  header.main-header div.header-paragraph p button {
    margin: 10px auto 0 auto;
  }

  header.main-header div.header-paragraph {
    font-size: 2.5em;
  }
  header.main-header div.header-end {
    font-size: 1.5em;
  }

  header.main-header div.header-paragraph p {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  header.main-header div.navbar {
    flex-direction: column;
  }

  header.main-header div.navbar ul {
    margin-top: 10px;
  }
}

/**
  End of header
*/

/**
  Start of mission
*/

div.mission {
  background-image: url("/src/images/rectangle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px;
  margin-bottom: 0;
  border-radius: 40px;
}

div.mission img {
  width: 100%;
  object-fit: contain;
}

div.mission img.icons {
  padding: 40px 70px;
}

div.mission div.images {
  padding: 0 20px;
}

div.mission div.images div img {
  width: 47%;
  object-fit: contain;
  border-radius: 20px;
}

div.mission div.images div:first-of-type img {
  position: relative;
  left: 46%;
}

div.mission div.images div:last-of-type img {
  position: relative;
  opacity: 0.85;
  transform: translateY(-58%);
  margin-bottom: -150px;
  left: 7%;
}

@media (max-width: 1000px) {
  div.mission div.images div:last-of-type img {
    margin-bottom: -130px;
  }
}

@media (max-width: 800px) {
  div.mission div.images div img {
    width: 100%;
    object-fit: contain;
  }
  div.mission div.images {
    padding: 0 15px;
  }
  div.mission div.images div:last-of-type img {
    opacity: 1;
    transform: translateY(0);
    margin-top: 10px;
    margin-bottom: 20px;
    left: 0;
  }
  div.mission div.images div:first-of-type img {
    left: 0;
  }
  div.mission {
    margin: 5%;
  }
  div.mission div.images {
    margin-top: 0px;
  }
  div.mission img.icons {
    padding: 20px 30px;
  }
}

/**
  End of mission
*/

/**
  Start of services
*/

div.services {
  padding: 40px 50px;
  background-image: radial-gradient(rgba(0, 28, 29, 0.8) 20%, #000);
  padding-bottom: 70px;
}

div.services div.paragraphs {
  position: relative;
}

div.services p.awesome,
div.services p.inside-awesome {
  font-size: 3.5em;
  color: var(--secondar-color);
  margin: auto;
  text-align: center;
}

div.services p.awesome {
  display: none;
}

div.services div.besharp-services div.service p.inside-awesome {
  margin-bottom: 140px;
  font-size: 3.5em;
}

div.services p {
  color: #fff;
}

div.services p.what {
  font-size: 700%;
  font-weight: bold;
  text-align: center;
  color: #7b7b7b;
  text-shadow: 3px 0 #fff, -3px 0 #fff, 0 3px #fff, -3px 0 #fff;
  letter-spacing: 0.08em;
}

div.services p.we-do {
  font-size: 250%;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 87%;
  left: 48%;
  transform: translate(-50%, -50%);
}

@media (max-width: 800px) {
  div.services div.besharp-services div.service p.inside-awesome {
    display: none;
  }

  div.services p.awesome {
    display: block;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  div.services p.what {
    font-size: 400%;
  }

  div.services p.we-do {
    font-size: 100%;
  }

  div.services p.awesome {
    font-size: 2.5em;
  }
  div.services p.we-do {
    left: 45%;
  }
}

/**
  Start of services
*/

div.services div.besharp-services {
  display: flex;
  color: #fff;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-end;
}

div.services div.besharp-services div.service {
  flex-basis: 32%;
  text-align: center;
}

div.services div.besharp-services div.service div {
  padding: 20px;
}

div.services div.besharp-services div.service div h3 {
  margin-top: 40px;
  font-size: 1.9em;
  margin-bottom: 80px;
  font-size: 1.8em;
  font-weight: 500;
  color: #00a0a3;
}

div.paragraphs {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

div.paragraphs p {
  font-size: 2.2em;
  margin: 0 20px;
  font-weight: 300;
}

/*
div.services div.besharp-services div.service:nth-child(1) div h3 {
  margin-bottom: 150px;
}

div.services div.besharp-services div.service:nth-child(2) div h3 {
  margin-bottom: 100px;
}

div.services div.besharp-services div.service:nth-child(3) div h3 {
  margin-bottom: 300px;
}
*/
div.services div.besharp-services div.service p {
  margin-bottom: 15px;
  font-size: 1.8em;
  color: #00a0a3;
  font-weight: 500;
}

div.services div.besharp-services div.service img {
  width: 40%;
  height: 150px;
}

@media (max-width: 800px) {
  div.services div.besharp-services {
    align-content: flex-start;
  }
  div.services div.besharp-services div.service {
    flex-basis: 49%;
    margin-bottom: 10px;
  }

  div.services div.besharp-services div.service:nth-child(1) div h3,
  div.services div.besharp-services div.service:nth-child(2) div h3,
  div.services div.besharp-services div.service:nth-child(3) div h3 {
    margin-bottom: 100px;
    margin-top: 0;
    font-size: 1.3em;
    font-weight: 100;
  }
}

@media (max-width: 700px) {
  div.services {
    padding: 40px 25px;
    padding-bottom: 70px;
  }

  div.services div.besharp-services div.service {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
}

/**
  End of services
*/

/**
  Start of contact
*/

div.contact {
  background: url("/src/images/building.jpg");
  padding: 50px;
  margin: 0 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 10px dashed #00a0a3;
  border-bottom: none;
}

div.contact p {
  color: #fff;
  overflow: hidden;
  font-size: 2.7em;
  font-family: cursive;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-family: "italic";
}

div.contact p span {
  color: var(--secondar-color);
}

div.contact form {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

div.contact form div {
  flex-basis: 30%;
}

div.contact form input {
  display: block;
  margin: auto;
  width: 100%;
  margin-top: 8px;
  padding: 10px 10px;
  background: rgba(51, 59, 67, 1);
  border: none;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
}

div.contact form input:focus {
  outline: none;
}

div.contact form label {
  display: block;
  color: #fff;
  margin-bottom: 25px;
}

div.contact form label textarea {
  display: block;
  color: #fff;
  margin-top: 5px;
  border-radius: 5px;
  width: 100%;
  padding: 10px 10px;
  background: rgba(51, 59, 67, 1);
  border: none;
  border: 1px solid #fff;
}

div.contact form label textarea:focus {
  outline: none;
}

div.contact div.form button {
  margin: auto;
  display: block;
  background: linear-gradient(
    180.2deg,
    #ee283b 0.18%,
    rgba(29, 131, 134, 0) 225.09%
  );
  color: #fff;
  padding: 15px 40px;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  margin-top: 30px;
  cursor: pointer;
}

div.contact div.form button div.loader {
  width: 25px;
  height: 25px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  padding: 12px;
  animation: loader 1s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  div.contact form div {
    flex-basis: 47%;
  }
}

@media (max-width: 700px) {
  div.contact {
    margin: 0 25px;
  }
}

@media (max-width: 450px) {
  div.contact form div {
    flex-basis: 100%;
  }
  header.main-header div.header-end div.flexing span {
    font-size: 1.1em;
  }
  p.header-odoo span {
    font-size: 1em;
  }
  div.contact {
    padding: 30px 20px;
  }
}

/**
  End of contact
*/

/**
  Start of footer
*/
div.stretching {
  padding: 50px;
  background: #00a0a3;
}

footer {
  padding: 70px;
  text-align: center;
  color: #fff;
}

footer img {
  width: 200px;
  max-width: 90%;
}

footer div.social-media {
  margin-top: 15px;
  font-size: 2em;
}

footer div.social-media i {
  margin-right: 15px;
}

footer p,
footer a {
  display: block;
  font-size: 1.2em;
  margin-top: 20px;
}

footer a {
  text-decoration: none;
  color: #fff;
}

footer p i,
footer a i {
  font-size: 1.3em;
  text-align: left;
}

footer p:first-of-type i {
  margin-right: 35px;
}

footer p:nth-of-type(2) i {
  margin-right: 52px;
}

footer a:nth-of-type(2) i {
  margin-right: 18px;
}

footer a:nth-of-type(1) i {
  margin-right: 51px;
}

footer p span,
footer a span {
  text-align: left;
  display: inline-block;
}

footer p.rights {
  padding: 30px 0 0 0;
  font-weight: bold;
}

@media (max-width: 400px) {
  footer {
    padding: 40px 0;
  }
}

/**
  End of footer
*/

/**
  Drop Down
*/

div.dropdown {
  position: relative;
  cursor: pointer;
}

div.dropdown ul {
  position: absolute;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  width: 6rem;
  left: -125%;
  /**
    To Move 
  */
}

div.dropdown ul li {
  display: block;
  text-align: center;
  color: #000;
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
}

div.dropdown ul li:last-child {
  border-bottom: none;
}

header.main-header div.navbar div.dropdown img {
  width: 25px;
  height: 25px;
}
