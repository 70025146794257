* {
  direction: ltr;
}

div.mission div.images div:first-of-type img {
  left: 46%;
}

div.mission div.images div:last-of-type img {
  left: 7%;
}

@media (max-width: 800px) {
  div.mission div.images div:last-of-type img {
    right: 0;
    left: 0;
  }
  div.mission div.images div:first-of-type img {
    left: 0;
    right: 0;
  }
}
